import SvgIcon from '@mui/material/SvgIcon';

function ButtonUp(props) {
  return (
    <SvgIcon {...props} width="50" height="50" viewBox="0 0 50 50">
      <rect width="50" height="50" rx="50" fill="currentColor" fill-opacity="0.95" stroke="none"></rect>
      <path d="M14 28L25 18l10 10" stroke="#000000" stroke-width="2" fill="none"></path>
    </SvgIcon>
  );
}

export default ButtonUp;
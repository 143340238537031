import SvgIcon from '@mui/material/SvgIcon';

function TgIcon(props) {
  return (
    <SvgIcon {...props} width="30" height="30" viewBox="0 0 30 30">
      <path fill-rule="evenodd" clip-rule="evenodd" d="M15 30C23.2843 30 30 23.2843 30 15C30 6.71574 23.2843 0 15 0C6.71574 0 0 6.71574 0 15C0 23.2843 6.71574 30 15 30ZM21.5932 9.58332C21.7088 8.26929 20.3212 8.81034 20.3212 8.81034C19.2966 9.23448 18.24 9.66549 17.1719 10.1012C13.8602 11.452 10.4389 12.8476 7.52433 14.2211C5.94399 14.8008 6.86907 15.3806 6.86907 15.3806L9.37449 16.1535C10.5308 16.5013 11.1476 16.1149 11.1476 16.1149L16.5438 12.4433C18.471 11.1293 18.0085 12.2114 17.546 12.6752L13.4987 16.54C12.882 17.081 13.1904 17.5448 13.4602 17.7767C14.2249 18.4514 16.106 19.6859 16.9272 20.2248C17.1409 20.365 17.2829 20.4581 17.3147 20.4821C17.5074 20.6367 18.5481 21.3323 19.2419 21.1777C19.9358 21.0232 20.0128 20.1343 20.0128 20.1343L20.9379 14.0665C21.0767 13.0131 21.2358 12.0006 21.3629 11.1919C21.483 10.4281 21.5744 9.84615 21.5932 9.58332Z" fill="currentColor"/>
    </SvgIcon>
  );
}

export default TgIcon;
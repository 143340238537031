import SvgIcon from '@mui/material/SvgIcon';

function VkIcon(props) {
  return (
    <SvgIcon {...props} width="30" height="30" viewBox="0 0 30 30">
      <path fill-rule="evenodd" clip-rule="evenodd" d="M15 30C23.2843 30 30 23.2843 30 15C30 6.71574 23.2843 0 15 0C6.71574 0 0 6.71574 0 15C0 23.2843 6.71574 30 15 30ZM7.5 10.2C7.6218 16.0463 10.5449 19.5598 15.6698 19.5598H15.9604V16.215C17.8436 16.4024 19.2676 17.7797 19.8391 19.5598H22.5C21.7692 16.8989 19.8485 15.428 18.6492 14.8658C19.8485 14.1725 21.5349 12.4861 21.9378 10.2H19.5205C18.9958 12.0551 17.4407 13.7415 15.9604 13.9008V10.2H13.5431V16.6835C12.044 16.3087 10.1515 14.4911 10.0672 10.2H7.5Z" fill="currentColor"/>
    </SvgIcon>
  );
}

export default VkIcon;
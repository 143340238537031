import logo from '../assets/logo.svg';
import TgFooterChat from './icons/TgFooterChat'
import TgFooterChannel from './icons/TgFooterChannel'
import VkFooter from './icons/VkFooter'
import YtFooter from './icons/YtFooter'

function Footer() {
  return (
    <footer className="footer">
      <div className="container">
        <div className="footer-wrapper">
          <a href="https://uic.dev/" className='footer__logo'>
            <img src={logo} alt="" />
          </a>
          <div className="footer__text">
            ТА САМАЯ<br/>
            УМНАЯ<br/>
            ВЕЧЕРИНКА
          </div>
          <div className="footer__menu">
            <div className="footer-title">Меню</div>
            <a className='footer-link' href="/">Программа</a>
            <a className='footer-link' href="/">Спикеры</a>
            <a className='footer-link' href="https://uic.dev/#rec623179507">Билеты</a>
            <a className='footer-link' href="https://uic.dev/#rec632216110">Партнеры</a>
          </div>
          <div className="footer__contacts">
            <div className="footer-title">контакты</div>
            <a className='footer-link' href="mailto:org@uic.dev">org@uic.dev</a>
            <a className='footer-link' href="tel:+78006006377">+7 (800) 600-63-77</a>
            <div className="footer-socials">
              <a className='footer__social' href="https://t.me/uicdev">
                <TgFooterChat />
              </a>
              <a className='footer__social' href="https://t.me/uic_dev">
                <TgFooterChannel />
              </a>
              <a className='footer__social' href="https://vk.com/uicdev">
                <VkFooter />
              </a>
              <a className='footer__social' href="https://vk.com/away.php?to=https%3A%2F%2Fwww.youtube.com%2F%40PicomLtd">
                <YtFooter />
              </a>
            </div>
          </div>
          <div className="footer__emails">
            По вопросам партнерства: partner@uic.dev<br/>
            По вопросам инфопарнерства: pr@uic.dev<br/>
            Для докладчиков: speaker@uic.dev
          </div>
          <div className="footer__links">
            <a className='footer-link' href="https://uic.dev/privacy_policy">Политика конфиденциальности</a>
            <div className='footer-link'>
              Организатор мероприятия <a className='footer-link--organizator' href="https://picom.ru/">Picom</a>
            </div>
          </div>
          <div className="footer__rights">© All Rights Reserved. Picom</div>
        </div>
      </div>

    </footer>
  )
}

export default Footer;
import MainLayout from "../layouts/MainLayout";
import { useState, useEffect } from "react";
import RequestManager from "../services/RequestManager";
import { NavLink } from "react-router-dom";
import SpeakerPlaceholder from "../assets/speaker.jpg"
import ArrowDown from "../components/icons/ArrowDown"

function useForceUpdate() {
  const [value, setValue] = useState(0); // integer state
  return () => setValue(value => value + 1); // update state to force render
  // A function that increment 👆🏻 the previous state like here
  // is better than directly setting `setValue(value + 1)`
}

function Speakers() {
  const forceUpdate = useForceUpdate();

  const [sections, setSections] = useState([]);
  const [speeches, setSpeeches] = useState([]);
  const [selectedSections, setSelectedSections] = useState([]);
  const [showFilter, setShowFilter] = useState(false);

  const onButtonClick = (id) => {
    let res = selectedSections;
    console.log(speeches)
    if (selectedSections.includes(id)) {
      const index = selectedSections.findIndex(x => x === id);
      res.splice(index, 1);
    } else {
      res.push(id);
    }
    setSelectedSections(res);
    forceUpdate();
  }

  const getSections = async () => {
    try {
      const res = await RequestManager.General.getSections();
      setSections(res);
    } catch (e) {
      console.log(e);
    }
  }

  const getSpeakers = async () => {
    try {
      const res = await RequestManager.General.getSpeakers();
      setSpeeches(res);
    } catch (e) {
      console.log(e);
    }
  }

  useEffect(() => {
    getSections();
    getSpeakers();
  }, []);


  return (
    <MainLayout>
      <div className="container">
        <h1 className="h1">Спикеры</h1>
      </div>
      <div className="container category-select--sticky">
        <div className="category-select">
          <div className="category-select__title">Выберите категорию:</div>
          <div className={`category-select__sections ${showFilter && 'active'}`}>
            {sections.map(section =>
              <button
                key={section.id}
                className="category-select__button"
                style={{
                  backgroundColor: selectedSections.includes(section.id) && section.color,
                  borderColor: selectedSections.includes(section.id) && section.color
                }}
                onClick={() => onButtonClick(section.id)}
              >{section.name}</button>
            )}
          </div>
          <button
            className={`category-select__expand ${showFilter && 'active'}`}
            onClick={() => setShowFilter(!showFilter)}
          >
            <ArrowDown />
          </button>
        </div>
      </div>
      <div className="container">
        <div className="speakers-list">
          {speeches.map(speech =>
            <>
              {(!selectedSections.length || selectedSections.includes(speech.section.id)) && speech.show &&
                <div className="speech">
                  <div className="speech__section" style={{ backgroundColor: speech.section.color }}>{speech.section.name}</div>
                  <div>
                    <div className="speech-photo">
                      <div className="speech-photo__background" style={{ backgroundColor: speech.section.color }}></div>
                      <img src={speech.speech?.person?.photo ? `${process.env.REACT_APP_API_ENDPOINT}${speech.speech?.person?.photo}` : SpeakerPlaceholder} alt="" />
                    </div>
                  </div>
                  <div className="speech-info">
                    <h3 className="speaker-name">{speech.speech?.person?.name || ''}</h3>
                    <div className="speaker-city">
                      <div className="speaker-city">
                        {speech.speech?.person?.company?.name || ''}
                        {speech.speech?.person?.company?.name && speech.speech?.person?.position && ', '}
                        {speech.speech?.person?.position || ''}
                        {speech.speech?.person?.city ? ` (${speech.speech?.person?.city})` : ''}
                      </div>
                    </div>
                    <NavLink to={`/speakers/${speech.speech.id}`}>
                      <h2 className="speech-name">{speech.speech.speech_name}</h2>
                    </NavLink>
                  </div>
                </div>
              }
            </>
          )}
        </div>
      </div>
    </MainLayout>
  );
}

export default Speakers;

import { createSlice } from '@reduxjs/toolkit'

export const userSlice = createSlice({
  name: 'user',
  initialState: {
    user: null,
    isLoaded: false,
  },
  reducers: {
    setUser: (state, action) => {
      state.user = action.payload
    },
    setUserIsLoaded: (state, action) => {
      state.isLoaded = action.payload
    }
  },
})

// Action creators are generated for each case reducer function
export const { setUser, setUserIsLoaded } = userSlice.actions

export default userSlice.reducer
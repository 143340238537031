import SvgIcon from '@mui/material/SvgIcon';

function YtIcon(props) {
  return (
    <SvgIcon {...props} width="30" height="30" viewBox="0 0 30 30">
      <path fill-rule="evenodd" clip-rule="evenodd" d="M15 30C23.2843 30 30 23.2843 30 15C30 6.71574 23.2843 0 15 0C6.71574 0 0 6.71574 0 15C0 23.2843 6.71574 30 15 30ZM20.3698 9.78768C21.5213 9.89172 22.1701 10.5962 22.3123 11.7192C22.4118 12.5027 22.5 13.6249 22.5 14.8316C22.5 16.9508 22.3123 18.176 22.3123 18.176C22.1357 19.2945 21.595 19.9018 20.458 20.0304C19.1876 20.174 16.3304 20.2401 15.0608 20.2401C13.7468 20.2401 11.0893 20.185 9.76278 20.0745C8.62251 19.9796 7.84443 19.3426 7.68768 18.2091C7.57368 17.3836 7.5 16.4026 7.5 14.8426C7.5 13.106 7.56264 12.3712 7.70973 11.5867C7.9197 10.4671 8.45394 9.92478 9.67431 9.79869C10.9549 9.66615 12.1651 9.6 14.8731 9.6C16.197 9.6 19.0513 9.66855 20.3698 9.78768ZM13.4051 12.7162V17.0282L17.5109 14.8501L13.4051 12.7162Z" fill="currentColor"/>
    </SvgIcon>
  );
}

export default YtIcon;
import MainLayout from "../layouts/MainLayout";
import { useState, useEffect } from "react";
import RequestManager from "../services/RequestManager";

function Live() {

  const [sections, setSections] = useState([]);

  const getSections = async () => {
    try {
      const res = await RequestManager.General.getSections();
      setSections(res);
    } catch (e) {
      console.log(e);
    }
  }

  useEffect(() => {
    getSections();
  }, []);


  return (
    <MainLayout>
      <div className="container">
        <h1 className="h1">Онлайн-трансляция</h1>
        <h4>Для того, чтобы присоединиться к трансляции, выберите секцию.</h4>
        <h4> <a className="bot-info-link" href="https://t.me/uic_dev_bot/">Задать вопрос спикеру можно в телеграм-боте.</a></h4>
        <h4><a className="bot-info-link" href="https://t.me/uicdev/">Все анонсы докладов конференции - в канале.</a></h4>
        <div className="links-wrapper">
          {sections.map(section => {
            return !!section.live ?
              <a href={section.live} className="live-link" key={section.id}>{section.location} {section.name}</a>
              :
              <></>
          }
          )}
        </div>
      </div>
    </MainLayout>
  );
}

export default Live;

import { useEffect, useState } from "react";
import { Formik } from "formik";
import Modal from 'react-bootstrap/Modal';
import Spinner from 'react-bootstrap/Spinner';
import Input from "./Input";
import useAuth from "../core/useAuth";

function LoginModal(props) {

  const { user, login } = useAuth();

  return (
    <Modal
      {...props}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          Авторизация
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Formik
          initialValues={{ login: null, password: null }}
          validate={values => {
            const errors = {};
            if (!values.login) {
              errors.login = 'Заполните поле';
            } else if (values.login && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.login)) {
              errors.login = 'Неверный формат поля';
            }
            if (!values.password) {
              errors.password = 'Заполните поле';
            }
            return errors;
          }}
          onSubmit={async (values, { setSubmitting, setStatus }) => {
            setSubmitting(true);
            setStatus({});
            try {
              await login(values);
              props.onHide();
            } catch (e) {
              console.log(e);
              setStatus({error: e.data.err})
            } finally {
              setSubmitting(false);
            }
          }}
        >
          {({
            values,
            errors,
            touched,
            status,
            handleChange,
            handleBlur,
            handleSubmit,
            isSubmitting
          }) => (
            <>
              <form onSubmit={handleSubmit}>
                <Input
                  className="mb-3"
                  label="Логин"
                  placeholder="Введите логин"
                  name="login"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.login}
                  error={errors.login && touched.login && errors.login}
                />
                <Input
                  type="password"
                  className="mt-3 mb-3"
                  label="Пароль"
                  placeholder="Введите пароль"
                  name="password"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.password}
                  error={errors.password && touched.password && errors.password}
                />
                <a href="/send-recovery-link" className="recovery-password-link">Восстановить пароль</a>
                {status?.error && <div className="text-danger my-3">{status.error}</div>}
                <button type="submit" className="button mt-4">
                  <span>Войти</span>
                  {isSubmitting &&
                    <Spinner animation="border" role="status" size="sm" className="ms-3">
                      <span className="visually-hidden">Loading...</span>
                    </Spinner>
                  }
                  </button>
              </form>
            </>
          )}
        </Formik>
      </Modal.Body>
    </Modal>
  );
}

export default LoginModal;
import axios from "axios";
import CookiesManager from "../CookiesManager";
import { handleResponseError } from "./globalErrorHandler";


const AUTH_TOKEN = {
	ACCESS_TOKEN: "ACCESS_TOKEN",
	REFRESH_TOKEN: "REFRESH_TOKEN"
}

const REQUEST_AUTH_TYPES = {
	PUBLIC: "PUBLIC",
	PRIVATE: "PRIVATE"
}

const METHOD_TYPES = {
	GET: "GET",
	POST: "POST",
	DELETE: "DELETE",
	PUT: "PUT"
}

const HOST_SCHEMA = {
	API: `${process.env.REACT_APP_API_ENDPOINT}`
};

const axiosApiInstance = axios.create();

axiosApiInstance.interceptors.request.use(
	(config) => {
		config.headers = {
			"Content-Type": "application/json",
			Accept: "application/json"
		};

		const authToken = CookiesManager.get(AUTH_TOKEN.ACCESS_TOKEN);
		if (config.requestAuthType === REQUEST_AUTH_TYPES.PRIVATE) {
			if (authToken) {
				config.headers.Authorization = `${authToken}`;
			}
		}

		delete config.REQUEST_AUTH_TYPE;
		return config;
	},
	(error) => {
		Promise.reject(error);
	}
);

axiosApiInstance.interceptors.response.use(
	(response) => {
		if (response.config.responsePrepare) {
			response.data = response.config.responsePrepare(response.data);
			delete response.config.responsePrepare;
		}
		return response;
	},
	async (error) => {
		const originalRequest = error.config;
		handleResponseError(error, originalRequest, axiosApiInstance);
		return error.response;
	}
);

const prepareUrl = (requestUrl) => {
	const requestUrlArray = requestUrl.split("://");
	if (requestUrlArray.length > 2) {
		console.warn("[REQUEST MANAGER] prepareUrl not correct requestUrl", requestUrl);
	} else if (requestUrlArray.length === 2) {
		const host = requestUrlArray[0].toUpperCase();
		if (HOST_SCHEMA[host]) {
			requestUrl = HOST_SCHEMA[host] + requestUrlArray[1];
		} else {
			console.warn("[REQUEST MANAGER] prepareUrl not correct hostSchema", requestUrl);
		}
	}
	return requestUrl;
};

const prepareRequestMethod = (requestMethod) => {
	const requestMethodMatch = /[a-zA-Z]+:[a-zA-Z]+/.test(requestMethod);
	const [methodType, authType] = requestMethod.split(":");
	if (requestMethodMatch && methodType in METHOD_TYPES && authType in REQUEST_AUTH_TYPES) {
		return {
			_requestNativeMethod: METHOD_TYPES[methodType],
			_requestAuthType: REQUEST_AUTH_TYPES[authType]
		};
	} else {
		throw new Error(
			`[REQUEST MANAGER] requestAuthType ${authType} is not correct type. The PRIVATE type will be set by default`
		);
	}
};

const prepareData = (requestData) => {
	return {
		_requestParams: requestData.params ? requestData.params : {},
		_requestData: requestData.data ? requestData.data : {}
	};
};

const SendRequest = (requestMethod, requestUrl, requestData, responsePrepare) => {
	const { _requestNativeMethod, _requestAuthType } = prepareRequestMethod(requestMethod);

	const _requestUrl = prepareUrl(requestUrl);

	const { _requestParams, _requestData } = prepareData(requestData);

	const axiosObject = {
		baseURL: "",
		method: _requestNativeMethod,
		url: _requestUrl,
		params: _requestParams,
		data: _requestData,
		requestAuthType: _requestAuthType,
		responsePrepare: responsePrepare
	};

	return axiosApiInstance(axiosObject).then(function (response) {
		if (response.status !== 200) {
			return Promise.reject(response);
		}
		return response.data;
	});
};

export default SendRequest;

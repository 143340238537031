import Cookie from "cookie-universal";
const cookies = Cookie();
class CookiesWorker {

	add(key, val) {
		const date = new Date();
		// Set it expire in  day
		date.setTime(date.getTime() + 1 * 24 * 60 * 60 * 1000);
		// Set it
		cookies.set(key, val, { expires: date, path: '/' });
	}

	get(key) {
		return cookies.get(key);
	}

	remove(key) {
		cookies.remove(key);
	}
}

export default new CookiesWorker();

import React from 'react';
import ReactDOM from 'react-dom/client';
import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";

import { Provider } from 'react-redux'
import store from './store/index'
import 'bootstrap/dist/css/bootstrap.min.css';
import './styles/index.scss';
import reportWebVitals from './reportWebVitals';
import Main from './pages/Main';
import Speakers from './pages/Speakers';
import Speech from './pages/Speech';
import Live from './pages/Live';
import SendRecoveryLink from './pages/SendRecoveryLink';
import Recovery from './pages/Recovery';
import ChangePassword from './pages/ChangePassword'

const router = createBrowserRouter([
  {
    path: "/",
    element: <Main />,
  },
  {
    path: "/speakers",
    element: <Speakers />,
  },
  {
    path: "/speakers/:id",
    element: <Speech />,
  },
  {
    path: "/live",
    element: <Live />,
  },
  {
    path: "/send-recovery-link",
    element: <SendRecoveryLink />,
  },
  {
    path: "/recovery/:token",
    element: <Recovery />,
  },
  {
    path: "/change-password",
    element: <ChangePassword />,
  },
]);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <Provider store={store}>
    <RouterProvider router={router} />
  </Provider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

import SendRequestService from "./SendRequestService";
// Groups
import General from "./General";
import Auth from "./Auth";

class RequestManager {
	General
	Auth

	constructor() {
		this.General = new General(SendRequestService);
		this.Auth = new Auth(SendRequestService);
	}
}

export default new RequestManager();
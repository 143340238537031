import React, { useEffect } from 'react';
import Header from '../components/Header';
import Footer from '../components/Footer';
import ButtonUp from '../components/icons/ButtonUp'
import LoginModal from '../components/LoginModal';
import useAuth from '../core/useAuth';

function MainLayout(props) {

  const { user, getMe } = useAuth();

  const scrollToTop = () => {
    document.body.scrollTop = 0; // For Safari
    document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
  }

  function scrollFunction() {
    let mybutton = document.querySelector(".button-up");
    if (document.body.scrollTop > 20 || document.documentElement.scrollTop > 20) {
      mybutton.style.visibility = "visible";
      mybutton.style.opacity = 1;
    } else {
      mybutton.style.visibility = "hidden";
      mybutton.style.opacity = 0;
    }
  }

  function buyTicket () {
    document.getElementById('buy-ticket').click();
  }

  useEffect(() => {
    window.onscroll = function() {scrollFunction()};
    getMe();
  }, [])

  return (
    <div className="layout">
      <Header></Header>
      <LoginModal show={false}></LoginModal>
      <main>
        {props.children}
        <div className="tickets-block">
          {user && user.access23 ?
            <>
              <a href="https://disk.yandex.ru/d/hNCD-bNSsBnnCg" className="button" target='_blank' rel="noreferrer" style={{ width: '278px'}}>Все презентации</a>
              <a href="https://www.youtube.com/playlist?list=PL4xnWHit7AdAXF_JKVj34a7Vi-wZxHHdI" className="button" target='_blank' rel="noreferrer" style={{ width: '278px'}}>Все видео</a>
            </>
            :
            <button className='button' onClick={() => buyTicket()} style={{ width: '278px'}}>Купить записи</button>
          }
        </div>
      </main>
      <Footer></Footer>
      <button className="button-up" style={{ visibility: 'hidden', opacity: 0 }} onClick={() => scrollToTop()}>
        <ButtonUp></ButtonUp>
      </button>
    </div>
  );
}

export default MainLayout;

const prepareRes = (res) => {
	return res;
};

export default class General {
	sendRequestService;

	constructor(SendRequestService) {
		this.sendRequestService = SendRequestService;
	}

	getSlots() {
		return this.sendRequestService("GET:PUBLIC", `api://timetable/slots`, prepareRes);
	}

	getPrograms() {
		return this.sendRequestService("GET:PUBLIC", `api://timetable/programs`, prepareRes);
	}

	getSections() {
		return this.sendRequestService("GET:PUBLIC", `api://timetable/sections`, prepareRes);
	}

	getEvents() {
		return this.sendRequestService("GET:PUBLIC", `api://timetable/events`, prepareRes);
	}

	getSpeakers() {
		return this.sendRequestService("GET:PUBLIC", `api://timetable/speakers`, prepareRes);
	}

	getSpeech(id) {
		return this.sendRequestService("GET:PUBLIC", `api://timetable/speech/${id}`, prepareRes);
	}
}

import Form from 'react-bootstrap/Form';

function Input(props) {

  const { label, error } = props

  return (
    <div className={`input-wrapper ${props.className}`}>
      <Form.Label htmlFor="inputPassword5" className='input-label'>{label}</Form.Label>
      <Form.Control
        id={label}
        aria-describedby="passwordHelpBlock"
        {...props}
        className="input-field"
      />
      {error && <Form.Text id={label} muted>
        { error }
      </Form.Text>}
    </div>
  );
}

export default Input;

import CookiesManager from "../CookiesManager";

const showError = (error) => {
	// do nothing.
};

export const handleResponseError = async (error, originalRequest, axiosApiInstance) => {
	if (error.response?.status === 401 && !originalRequest._retry) {
		originalRequest._retry = true;
		// CookiesManager.remove("ACCESS_TOKEN")
		return axiosApiInstance(originalRequest);
	} else {
		showError(error);
	}
};

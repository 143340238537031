import { useState, useEffect } from "react";
import MainLayout from "../layouts/MainLayout";
import { Formik } from "formik";
import Spinner from 'react-bootstrap/Spinner';
import Input from "../components/Input";
import useAuth from "../core/useAuth";
import { useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";

function Recovery() {
  const navigate = useNavigate();

  const { token } = useParams();

  const { user, userIsLoaded, recovery } = useAuth();

  const [isSubmitted, setIsSubmitted] = useState(false);

  useEffect(() => {
    if (user && userIsLoaded) {
      navigate("/");
    }
  }, [user, userIsLoaded]);

  return (
    <MainLayout>
      <div className="container">
        <div className="recovery-password">
          <Formik
            initialValues={{ password: null, passwordConfirm: null }}
            validate={values => {
              const errors = {};
              if (!values.password) {
                errors.password = 'Заполните поле';
              }
              if (!values.passwordConfirm) {
                errors.passwordConfirm = 'Заполните поле';
              }
              if (values.password !== values.passwordConfirm) {
                errors.passwordConfirm = 'Пароли не совпадают';
              }
              return errors;
            }}
            onSubmit={async (values, { setSubmitting, setStatus }) => {
              setSubmitting(true);
              try {
                await recovery(token, { password: values.password });
                setIsSubmitted(true);
              } catch (e) {
                console.log(e);
                setStatus({error: e.data.err || 'Ошибка запроса'});
                setIsSubmitted(false);
              } finally {
                setSubmitting(false);
              }
            }}
          >
            {({
              values,
              errors,
              touched,
              status,
              handleChange,
              handleBlur,
              handleSubmit,
              isSubmitting,
              setFieldValue
            }) => (
              <>
                {!isSubmitted ?
                  <form onSubmit={handleSubmit}>
                    <h4 className="mb-4 text-center">Восстановление пароля</h4>
                    <Input
                      type="password"
                      className="mb-3"
                      label="Новый пароль"
                      placeholder="Введите новый пароль"
                      name="password"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.password}
                      error={errors.password && touched.password && errors.password}
                    />
                    <Input
                      type="password"
                      className="mb-3"
                      label="Подтвердите пароль"
                      placeholder="Подтвердите пароль"
                      name="passwordConfirm"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.passwordConfirm}
                      error={errors.passwordConfirm && touched.passwordConfirm && errors.passwordConfirm}
                    />
                    {status?.error && <div className="text-danger my-3">{status.error}</div>}
                    <button type="submit" className="button mt-4">
                      <span>Отправить</span>
                      {isSubmitting &&
                        <Spinner animation="border" role="status" size="sm" className="ms-3">
                          <span className="visually-hidden">Loading...</span>
                        </Spinner>
                      }
                      </button>
                  </form>
                  :
                  <div className="text-center">Вы успешно сменили пароль!</div>
                }
              </>
            )}
          </Formik>
        </div>
      </div>
    </MainLayout>
  );
}

export default Recovery;

import SvgIcon from '@mui/material/SvgIcon';

function TgFooterChat(props) {
  return (
    <SvgIcon {...props} width="30" height="30" viewBox="0 0 30 30">
      <path fill-rule="evenodd" clip-rule="evenodd" d="M29.7149 4.38856C29.8715 4.52212 29.9434 4.73003 29.9029 4.93179L25.0981 28.8302C25.0607 29.0164 24.9325 29.1715 24.7567 29.2433C24.581 29.3152 24.3809 29.2943 24.2237 29.1877L16.1818 23.7311L12.3015 27.482C12.1682 27.6109 11.9803 27.6665 11.7983 27.6308C11.6163 27.5951 11.4632 27.4727 11.3885 27.303L7.62433 18.7617L0.775088 15.9129C0.560026 15.8235 0.421532 15.6116 0.425881 15.3787C0.43023 15.1459 0.576539 14.9393 0.79479 14.858L29.1488 4.28866C29.3417 4.21677 29.5583 4.25501 29.7149 4.38856ZM8.7826 18.5775L11.3403 24.3813V20.1455C11.3403 19.9864 11.4072 19.8346 11.5246 19.7272L18.3995 13.4361L8.7826 18.5775ZM12.4745 25.7374L15.223 23.0805L12.4745 21.2156V25.7374ZM12.8198 20.0793L24.1544 27.77L28.5901 5.70733L2.53895 15.4182L8.02135 17.6985L21.8427 10.3092C22.0948 10.1744 22.4078 10.2483 22.5729 10.4816C22.7381 10.7149 22.7038 11.0347 22.4929 11.2277L12.8198 20.0793Z" fill="#E0431E"/>
      <path d="M12.0508 20.1455V27.0743L16.2624 23.0031L12.0508 20.1455Z" fill="#E0431E"/>
      <path d="M29.3472 4.82031L0.993164 15.3896L8.05201 18.3255L22.1104 10.8096L11.9077 20.1458L16.1193 23.0034L24.5424 28.7187L29.3472 4.82031Z" fill="#E0431E"/>
      <path fill-rule="evenodd" clip-rule="evenodd" d="M0 2.97717C0 1.33293 1.33293 0 2.97717 0H10.6328C12.277 0 13.6099 1.33293 13.6099 2.97717V4.57067C13.6099 6.52811 12.0231 8.11492 10.0657 8.11492H5.42731L3.5852 10.2395C2.98346 10.9335 1.84301 10.5079 1.84301 9.58938V8.06067C0.789003 7.83 0 6.89106 0 5.76784V2.97717ZM2.97717 1.4177C2.1159 1.4177 1.4177 2.1159 1.4177 2.97717V5.76784C1.4177 6.28112 1.8338 6.69722 2.34708 6.69722C2.85167 6.69722 3.26071 7.10627 3.26071 7.61085V8.44963L4.48336 7.0395C4.67186 6.82211 4.94543 6.69722 5.23316 6.69722H10.0657C11.2401 6.69722 12.1922 5.74513 12.1922 4.57067V2.97717C12.1922 2.1159 11.494 1.4177 10.6328 1.4177H2.97717Z" fill="#E0431E"/>
      <path d="M4.67878 3.96976C4.67878 4.43955 4.29794 4.82038 3.82816 4.82038C3.35838 4.82038 2.97754 4.43955 2.97754 3.96976C2.97754 3.49998 3.35838 3.11914 3.82816 3.11914C4.29794 3.11914 4.67878 3.49998 4.67878 3.96976Z" fill="#E0431E"/>
      <path d="M7.79694 3.96976C7.79694 4.43955 7.41611 4.82038 6.94632 4.82038C6.47654 4.82038 6.0957 4.43955 6.0957 3.96976C6.0957 3.49998 6.47654 3.11914 6.94632 3.11914C7.41611 3.11914 7.79694 3.49998 7.79694 3.96976Z" fill="#E0431E"/>
      <path d="M10.6329 3.96976C10.6329 4.43955 10.252 4.82038 9.78226 4.82038C9.31248 4.82038 8.93164 4.43955 8.93164 3.96976C8.93164 3.49998 9.31248 3.11914 9.78226 3.11914C10.252 3.11914 10.6329 3.49998 10.6329 3.96976Z" fill="#E0431E"/>
    </SvgIcon>
  );
}

export default TgFooterChat;
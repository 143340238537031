import SvgIcon from '@mui/material/SvgIcon';

function TgFooterChannel(props) {
  return (
    <SvgIcon {...props} width="30" height="30" viewBox="0 0 30 30">
      <path fill-rule="evenodd" clip-rule="evenodd" d="M29.8565 4.38856C30.0131 4.52212 30.085 4.73003 30.0444 4.93179L25.2397 28.8302C25.2023 29.0164 25.0741 29.1715 24.8983 29.2433C24.7226 29.3152 24.5225 29.2943 24.3653 29.1877L16.3234 23.7311L12.4431 27.482C12.3098 27.6109 12.1219 27.6665 11.9399 27.6308C11.7579 27.5951 11.6048 27.4727 11.5301 27.303L7.76593 18.7617L0.916689 15.9129C0.701628 15.8235 0.563133 15.6116 0.567482 15.3787C0.571832 15.1459 0.71814 14.9393 0.936392 14.858L29.2904 4.28866C29.4833 4.21677 29.6999 4.25501 29.8565 4.38856ZM8.9242 18.5775L11.4819 24.3813V20.1455C11.4819 19.9864 11.5488 19.8346 11.6662 19.7272L18.541 13.4361L8.9242 18.5775ZM12.6161 25.7374L15.3646 23.0805L12.6161 21.2156V25.7374ZM12.9614 20.0793L24.296 27.77L28.7317 5.70733L2.68055 15.4182L8.16295 17.6985L21.9843 10.3092C22.2364 10.1744 22.5494 10.2483 22.7145 10.4816C22.8797 10.7149 22.8454 11.0347 22.6345 11.2277L12.9614 20.0793Z" fill="#E0431E"/>
      <path d="M12.1924 20.1455V27.0743L16.404 23.0031L12.1924 20.1455Z" fill="#E0431E"/>
      <path d="M29.4888 4.82031L1.13477 15.3896L8.19361 18.3255L22.252 10.8096L12.0493 20.1458L16.2609 23.0034L24.684 28.7187L29.4888 4.82031Z" fill="#E0431E"/>
      <path d="M3.11894 8.93154C3.11894 9.79281 2.42074 10.491 1.55947 10.491C0.698199 10.491 0 9.79281 0 8.93154C0 8.07027 0.698199 7.37207 1.55947 7.37207C2.42074 7.37207 3.11894 8.07027 3.11894 8.93154Z" fill="#E0431E"/>
      <path fill-rule="evenodd" clip-rule="evenodd" d="M5.10373 10.4905C5.10373 7.6718 2.81871 5.38679 0 5.38679V3.68555C3.75828 3.68555 6.80497 6.73223 6.80497 10.4905H5.10373Z" fill="#E0431E"/>
      <path fill-rule="evenodd" clip-rule="evenodd" d="M6.38167 4.10932C4.87262 2.60026 2.68914 1.70124 0 1.70124V0C3.07877 0 5.71548 1.03721 7.58463 2.90636C9.45378 4.77551 10.491 7.41222 10.491 10.491H8.78975C8.78975 7.80185 7.89073 5.61837 6.38167 4.10932Z" fill="#E0431E"/>
    </SvgIcon>
  );
}

export default TgFooterChannel;
const prepareRes = (res) => {
	return res;
};

export default class Auth {
	sendRequestService;

	constructor(SendRequestService) {
		this.sendRequestService = SendRequestService;
	}

	login(data) {
		return this.sendRequestService("POST:PUBLIC", `api://user/login`, { data }, prepareRes);
	}
	sendRecoveryLink(data) {
		return this.sendRequestService("POST:PUBLIC", `api://user/sendRecoveryLink`, { data }, prepareRes);
	}
	recovery(token, data) {
		return this.sendRequestService("POST:PUBLIC", `api://user/recovery/${token}`, { data }, prepareRes);
	}
	changePassword(data) {
		return this.sendRequestService("POST:PRIVATE", `api://user/changePassword`, { data }, prepareRes);
	}
	getMe() {
		return this.sendRequestService("GET:PRIVATE", `api://user/getMe`, prepareRes);
	}
}

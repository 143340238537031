import { useState, useEffect } from "react";
import MainLayout from "../layouts/MainLayout";
import { Formik } from "formik";
import Spinner from 'react-bootstrap/Spinner';
import Input from "../components/Input";
import useAuth from "../core/useAuth";
import { useNavigate } from "react-router-dom";

function SendRecoveryLink() {
  const navigate = useNavigate();

  const { user, userIsLoaded, sendRecoveryLink } = useAuth();

  const [isSubmitted, setIsSubmitted] = useState(false);

  useEffect(() => {
    if (user && userIsLoaded) {
      navigate("/");
    }
  }, [user, userIsLoaded]);

  return (
    <MainLayout>
      <div className="container">
        <div className="recovery-password">
          <Formik
            initialValues={{ email: null }}
            validate={values => {
              const errors = {};
              if (!values.email) {
                errors.email = 'Заполните поле';
              } else if (values.email && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)) {
                errors.email = 'Неверный формат поля';
              }
              return errors;
            }}
            onSubmit={async (values, { setSubmitting, setStatus }) => {
              setSubmitting(true);
              setStatus({});
              try {
                await sendRecoveryLink(values);
                setIsSubmitted(true);
              } catch (e) {
                console.log(e);
                setStatus({error: e.data.err || 'Ошибка запроса'});
                setIsSubmitted(false);
              } finally {
                setSubmitting(false);
              }
            }}
          >
            {({
              values,
              errors,
              touched,
              status,
              handleChange,
              handleBlur,
              handleSubmit,
              isSubmitting,
              setFieldValue
            }) => (
              <>
                {!isSubmitted ?
                  <form onSubmit={handleSubmit}>
                    <h4 className="mb-4 text-center">Восстановление пароля</h4>
                    <Input
                      className="mb-3"
                      label="Email"
                      placeholder="Введите email"
                      name="email"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.email}
                      error={errors.email && touched.email && errors.email}
                    />
                    {status?.error && <div className="text-danger my-3">{status.error}</div>}
                    <button type="submit" className="button mt-4">
                      <span>Отправить</span>
                      {isSubmitting &&
                        <Spinner animation="border" role="status" size="sm" className="ms-3">
                          <span className="visually-hidden">Loading...</span>
                        </Spinner>
                      }
                      </button>
                  </form>
                  :
                  <div className="text-center">На указанную почту {values.email} отправлено письмо с инструкциями по восстановлению пароля</div>
                }
              </>
            )}
          </Formik>
        </div>
      </div>
    </MainLayout>
  );
}

export default SendRecoveryLink;

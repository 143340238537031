import { useLocation } from 'react-router-dom';
import logo from '../assets/logo.svg'
import VkIcon from './icons/vk'
import TgIcon from './icons/tg'
import YtIcon from './icons/yt'
import { useState } from 'react';
import ProfileIcon from './icons/ProfileIcon'
import useAuth from '../core/useAuth';
import LoginModal from '../components/LoginModal';
import { NavLink } from 'react-router-dom';

const menuLinks = [
  { title: 'Программа', href: '/' },
  { title: 'Спикеры', href: '/speakers' },
  { title: 'Билеты', href: 'https://uic.dev/#rec623179507' },
  { title: 'Как это было', href: 'https://uic.dev/#rec620941298' },
  { title: 'Контакты', href: 'https://uic.dev/#rec625372451' },
  { title: 'Партнеры', href: 'https://uic.dev/#rec632216110' },
]

function Header() {
  const location = useLocation();
  const { user, logout } = useAuth();

  const [showSideMenu, setShowSideMenu] = useState(false);
  const [showLogin, setShowLogin] = useState(false);

  const onProfileButtonClick = () => {
    if (!!user) {

    } else {
      setShowLogin(true);
    }
  }

  return(
    <>
      <LoginModal show={showLogin} onHide={() => setShowLogin(false)}></LoginModal>
      <header className="header">
        <div className="container">
          <div className="d-flex align-items-center justify-content-between justify-content-lg-start w-100">
            <a href="https://uic.dev/">
              <img className="header__logo" src={logo} alt="" />
            </a>
            <div className="header__menu-links d-none d-lg-flex">
              {menuLinks.map(x => (
                <a href={x.href} className={`menu-link ${location.pathname === x.href ? 'active' : ''}`}>{x.title}</a>
              ))}
            </div>
            <button className='profile-button d-none d-lg-flex' onClick={() => onProfileButtonClick()}>
              {user ? (
                <>
                  <ProfileIcon></ProfileIcon>
                  <span>{user.name}</span>
                </>
              ) :
                <span>Войти</span>
              }
              {user &&
                <div className="profile-button__menu">
                  <NavLink to='/change-password' className="profile-button__link">Изменить пароль</NavLink>
                  <div className="profile-button__link" onClick={logout}>Выйти</div>
                </div>
              }
            </button>
            <button className='burger-button d-flex d-lg-none' onClick={() => setShowSideMenu(true)}>
              <span></span>
              <span></span>
              <span></span>
            </button>
          </div>
        </div>
        {showSideMenu &&
          <div className="mask" onClick={() => setShowSideMenu(false)}></div>
        }
        <div className={`side-menu d-flex d-lg-none ${showSideMenu ? 'active' : ''}`}>
          <button className="side-menu__close" onClick={() => setShowSideMenu(false)}>
            <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M1 1.5L18.5 19" stroke="white" stroke-width="2"/>
              <path d="M1 19L18.5 1.5" stroke="white" stroke-width="2"/>
            </svg>
          </button>
          <div className="header__menu-links">
            <button className='profile-button' onClick={() => onProfileButtonClick()}>
              {user ? (
                <>
                  <ProfileIcon></ProfileIcon>
                  <span>{user.name}</span>
                </>
              ) :
                <span>Войти</span>
              }
            </button>
            {user &&
              <div className="profile-button__menu">
                <NavLink to='/change-password' className="profile-button__link">Изменить пароль</NavLink>
                <div className="profile-button__link" onClick={logout}>Выйти</div>
              </div>
            }
            {menuLinks.map(x => (
              <a href={x.href} className={`menu-link ${location.pathname === x.href ? 'active' : ''}`}>{x.title}</a>
            ))}
          </div>
          <div className="side-menu__footer">
            <div className="side-menu__text">Та самая умная вечеринка.</div>
            <div className="side-menu__socials">
              <a className='social' href="https://vk.com/uicdev">
                <VkIcon />
              </a>
              <a className='social' href="https://t.me/uicdev">
                <TgIcon />
              </a>
              <a className='social' href="https://www.youtube.com/@PicomLtd">
                <YtIcon />
              </a>
            </div>
          </div>
        </div>
      </header>
    </>
  )
}

export default Header;
import { useState, useEffect } from 'react'
import RequestManager from "../services/RequestManager";
import { NavLink } from "react-router-dom";

function ProgramGrid(props) {

  const { sections, selectedSections, onUpdate } = props;

  const [slots, setSlots] = useState([]);

  const [events, setEvents] = useState([]);

  const [programs, setPrograms] = useState([])

  const getSlots = async () => {
    try {
      const res = await RequestManager.General.getSlots();
      setSlots(res);
    } catch (e) {
      console.log(e);
    }
  }

  const getEvents = async () => {
    try {
      const res = await RequestManager.General.getEvents();
      setEvents(res);
    } catch (e) {
      console.log(e);
    }
  }

  const getPrograms = async () => {
    try {
      const res = await RequestManager.General.getPrograms();
      setPrograms(res);
    } catch (e) {
      console.log(e);
    }
  }

  useEffect(() => {
    getSlots();
    getPrograms();
    getEvents();
  }, []);

  useEffect(() => {
    setTimeout(() => {
      onUpdate();
    });
  }, [JSON.stringify(slots), JSON.stringify(programs), JSON.stringify(events), JSON.stringify(sections), JSON.stringify(selectedSections)])

  return (
    <table className="program-grid">
      <thead>
        <tr>
          {!!slots.length &&
            <th className="program-grid-time">Время</th>
          }
          {sections.map(section => (
            <>
              {(!selectedSections.length || selectedSections.includes(section.id)) &&
                <th className="program-grid-section">
                  <div className="program-grid-section__name">{section?.name || '-'}</div>
                  <div className="program-grid-section__location">{section?.location || '-'}</div>
                  {section.color &&
                    <div className="program-grid-section__color" style={{ backgroundColor: section.color }}></div>
                  }
                </th>
              }
            </>
          ))}
        </tr>
      </thead>
      <tbody>
        {slots.map(slot =>
          <tr key={slot.id}>
            <td className="program-grid-time">{slot.name}</td>

            {events.map(event => (
              <>
                {event.slot.id === slot.id &&
                  <td colSpan={!!selectedSections.length ? selectedSections.length : sections.length} className="program-grid-program">
                    <div className="program-grid-program__name">{event?.name || '-'}</div>
                    <div className="program-grid-program__location">{event?.location || '-'}</div>
                  </td>
                }
              </>
            ))}

            {!events.some(x => x.slot.id === slot.id) &&
              <>
                {sections.map((section, sectionNumber) => (
                  <>
                    {(!selectedSections.length || selectedSections.includes(section.id)) &&
                      <td className="program-grid-program program-card">
                        {programs.map(program => (
                          <>
                            {program.section && program.slot.id === slot.id && program.section.id === section.id && (!selectedSections.length || selectedSections.includes(program.section.id)) &&
                              <>
                                {program.section.color &&
                                  <div className="program-card__color" style={{ backgroundColor: program.section.color }}></div>
                                }
                                <NavLink to={`/speakers/${program.speech.id}`}>
                                  <div className="program-card__speaker">{program.speech.person?.first_name || ''} {program.speech.person?.last_name || ''}</div>
                                  <div className="program-card__company">{program.speech.person?.company?.name || '-'}</div>
                                  <div className="program-card__speech">{program.speech?.speech_name || ''}</div>
                                </NavLink>
                              </>
                            }
                          </>
                        ))}
                      </td>
                    }
                  </>
                ))}
              </>
            }

          </tr>
        )}
      </tbody>
    </table>
  )
}

export default ProgramGrid;
import RequestManager from '../services/RequestManager';
import CookiesManager from '../services/CookiesManager';
import { useSelector, useDispatch } from 'react-redux'
import { setUser, setUserIsLoaded } from '../store/userSlice';

function useAuth(){
  const dispatch = useDispatch()
  const user = useSelector((state) => state.user.user)
  const userIsLoaded = useSelector((state) => state.user.isLoaded)

  const login = async (values) => {
    try {
      const { token, userData } = await RequestManager.Auth.login(values);
      CookiesManager.add("ACCESS_TOKEN", token);
      dispatch(setUser(userData));
    } catch(e) {
      throw e;
    }
  };
  const sendRecoveryLink = async (values) => {
    try {
      const res = await RequestManager.Auth.sendRecoveryLink(values);
    } catch(e) {
      throw e;
    }
  };
  const recovery = async (token, values) => {
    try {
      const res = await RequestManager.Auth.recovery(token, values);
    } catch(e) {
      throw e;
    }
  };
  const changePassword = async (values) => {
    try {
      const res = await RequestManager.Auth.changePassword(values);
    } catch(e) {
      throw e;
    }
  };
  const logout = () => {
    CookiesManager.remove("ACCESS_TOKEN");
    dispatch(setUser(null));
  };
  const getMe = async () => {
    dispatch(setUserIsLoaded(false));
    try {
      const { userData } = await RequestManager.Auth.getMe();
      dispatch(setUser(userData));
    } catch(e) {
      console.log(e);
    } finally {
      dispatch(setUserIsLoaded(true));
    }
  };

  return {
    user,
    userIsLoaded,
    getMe,
    login,
    sendRecoveryLink,
    changePassword,
    recovery,
    logout
  }
}

export default useAuth;
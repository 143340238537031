import MainLayout from "../layouts/MainLayout";
import { useState, useEffect } from "react";
import RequestManager from "../services/RequestManager";
import { NavLink } from "react-router-dom";
import { useParams } from "react-router-dom";
import useAuth from "../core/useAuth";

import SpeakerPlaceholder from "../assets/speaker.jpg"

function Speech() {
  const { id } = useParams();
  const { user } = useAuth();
  const [data, setData] = useState(null);

  const getSpeech = async () => {
    try {
      const res = await RequestManager.General.getSpeech(id);
      setData(res);
    } catch (e) {
      console.log(e);
    }
  }

  function buyTicket () {
    document.getElementById('buy-ticket').click();
  }

  useEffect(() => {
    getSpeech();
  }, []);


  return (
    <MainLayout>
      <div className="container">
        <NavLink to='/speakers' className="back-button mt-lg-5">Все спикеры</NavLink>
        {data &&
          <div className="speech speech-page">
            <div className="speech__section" style={{ backgroundColor: data?.program?.section?.color }}>{data?.program?.section?.name}</div>
            <div>
              <div className="speech-photo">
                <div className="speech-photo__background" style={{ backgroundColor: data?.program?.section?.color }}></div>
                <img src={data.speech?.person?.photo ? `${process.env.REACT_APP_API_ENDPOINT}${data.speech?.person?.photo}` : SpeakerPlaceholder} alt="" />
              </div>
              <NavLink to="/" className="button mt-5" style={{ backgroundColor: data?.program?.section?.color }}>Смотреть программу</NavLink>
            </div>
            <div className="speech-info">
              <h3 className="speaker-name">{data?.speech?.person?.name}</h3>
              <div className="speaker-city">
                {data.speech?.person?.company?.name || ''}
                {data.speech?.person?.company?.name && data.speech?.person?.position && ', '}
                {data.speech?.person?.position || ''}
                {data.speech?.person?.city ? ` (${data.speech?.person?.city})` : ''}
              </div>
              <h2 className="speech-name">{data?.speech?.speech_name}</h2>
              <div className="speech-materials">
                {user && user.access23 && data.speech?.presentation ?
                  <a className="button" href={data.speech?.presentation} target="_blank" rel="noreferrer" style={{ backgroundColor: data?.program?.section?.color }}>Презентация</a>
                  :
                  (!user || !user.access23) && data.speech?.presentation &&
                    <button className="button" disabled>Презентация</button>
                }
                {user && user.access23 && data.speech?.video ?
                  <a className="button" href={data.speech?.video} target="_blank" rel="noreferrer" style={{ backgroundColor: data?.program?.section?.color }}>Видео</a>
                  :
                  (!user || !user.access23) && data.speech?.video &&
                    <button className="button" disabled>Видео</button>
                }
                {(!user || !user.access23) && (data.speech?.presentation || data.speech?.video) && (
                  <>
                    <div className="speech-materials__alert text-danger">Доступно участникам мероприятия или купившим записи</div>
                    <button className='button' onClick={() => buyTicket()}>Купить записи</button>
                  </>
                )}
              </div>
              {data?.speech?.abstract &&
                <div className="speech-description">
                  <h5>О чем поговорим:</h5>
                  <div dangerouslySetInnerHTML={{ __html: data?.speech?.abstract }} />
                </div>
              }
              {data.speech?.person?.description &&
                <div className="speech-description">
                  <h5>О себе:</h5>
                  <div dangerouslySetInnerHTML={{ __html: data.speech?.person?.description }} />
                </div>
              }
            </div>
          </div>
        }
      </div>
    </MainLayout>
  );
}

export default Speech;

import MainLayout from "../layouts/MainLayout";
import ProgramGrid from "../components/ProgramGrid";
import { useState, useEffect } from "react";
import RequestManager from "../services/RequestManager";

function useForceUpdate() {
  const [value, setValue] = useState(0); // integer state
  return () => setValue(value => value + 1); // update state to force render
  // A function that increment 👆🏻 the previous state like here
  // is better than directly setting `setValue(value + 1)`
}

function Main() {
  const forceUpdate = useForceUpdate();

  const [sections, setSections] = useState([]);
  const [selectedSections, setSelectedSections] = useState([]);

  const onButtonClick = (id) => {
    let res = selectedSections;
    if (selectedSections.includes(id)) {
      const index = selectedSections.findIndex(x => x === id);
      res.splice(index, 1);
    } else {
      res.push(id);
    }
    setSelectedSections(res);
    forceUpdate();
  }

  const getSections = async () => {
    try {
      const res = await RequestManager.General.getSections();
      setSections(res);
    } catch (e) {
      console.log(e);
    }
  }

  const setDoubleScroll = () => {
    let width = document.querySelector('.program-grid').offsetWidth;
    document.querySelector('.double-scroll').style.width = `${width}px`;

    var wrapper1 = document.getElementById('wrapper1');
    var wrapper2 = document.getElementById('wrapper2');
    wrapper1.onscroll = function() {
      wrapper2.scrollLeft = wrapper1.scrollLeft;
    };
    wrapper2.onscroll = function() {
      wrapper1.scrollLeft = wrapper2.scrollLeft;
    };
  }

  useEffect(() => {
    getSections();
    setDoubleScroll();
  }, []);


  return (
    <MainLayout>
      <div className="container">
        <h1 className="h1">программа uic dev 2023</h1>
        <h3 className="conference-date">
          <span>30 СЕНТЯБРЯ 2023</span>
          <span>ИЖЕВСК</span>
        </h3>
        <h4 className="conference-location">СЦ «ИНТЕГРАЛ», СТУДЕНЧЕСКАЯ, 7</h4>
        {!!sections.length &&
          <div className="category-select">
            <div className="category-select__title">Выберите категорию:</div>
            <div className="category-select__sections">
              {sections.map(section =>
                <button
                  key={section.id}
                  className="category-select__button"
                  style={{
                    backgroundColor: selectedSections.includes(section.id) && section.color,
                    borderColor: selectedSections.includes(section.id) && section.color
                  }}
                  onClick={() => onButtonClick(section.id)}
                >{section.name}</button>
              )}
            </div>
          </div>
        }
        <div className="program-grid-wrapper" id="wrapper1">
          <div className="double-scroll"></div>
        </div>
        <div className="program-grid-wrapper" id="wrapper2">
          <ProgramGrid sections={sections} selectedSections={selectedSections} onUpdate={() => setDoubleScroll()} />
        </div>
      </div>
    </MainLayout>
  );
}

export default Main;

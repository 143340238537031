import SvgIcon from '@mui/material/SvgIcon';

function YtFooter(props) {
  return (
    <SvgIcon {...props} width="30" height="30" viewBox="0 0 30 30">
      <path fill-rule="evenodd" clip-rule="evenodd" d="M3 0C1.34315 0 0 1.34315 0 3V27C0 28.6569 1.34315 30 3 30H27C28.6569 30 30 28.6569 30 27V3C30 1.34315 28.6569 0 27 0H3ZM22.3123 11.7191C22.1701 10.596 21.5213 9.89157 20.3698 9.78754C19.0513 9.66841 16.197 9.59986 14.8731 9.59986C12.1651 9.59986 10.9549 9.66601 9.67431 9.79855C8.45394 9.92464 7.9197 10.4669 7.70973 11.5865C7.56264 12.371 7.5 13.1059 7.5 14.8425C7.5 16.4024 7.57368 17.3835 7.68768 18.2089C7.84443 19.3425 8.62251 19.9794 9.76278 20.0743C11.0893 20.1848 13.7468 20.2399 15.0608 20.2399C16.3304 20.2399 19.1876 20.1738 20.458 20.0302C21.595 19.9017 22.1357 19.2943 22.3123 18.1759C22.3123 18.1759 22.5 16.9506 22.5 14.8314C22.5 13.6248 22.4118 12.5025 22.3123 11.7191ZM13.4051 17.0281V12.716L17.5109 14.85L13.4051 17.0281Z" fill="#E0431E"/>
    </SvgIcon>
  );
}

export default YtFooter;
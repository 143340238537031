import SvgIcon from '@mui/material/SvgIcon';

function VkFooter(props) {
  return (
    <SvgIcon {...props} width="30" height="30" viewBox="0 0 30 30">
      <path fill-rule="evenodd" clip-rule="evenodd" d="M3 0C1.34315 0 0 1.34315 0 3V27C0 28.6569 1.34315 30 3 30H27C28.6569 30 30 28.6569 30 27V3C30 1.34315 28.6569 0 27 0H3ZM15.6077 19.9063C10.3081 19.9063 7.28534 16.2731 7.15938 10.2274H9.81406C9.90123 14.6648 11.8583 16.5444 13.4084 16.9319V10.2274H15.9082V14.0544C17.439 13.8897 19.0471 12.1458 19.5896 10.2274H22.0893C21.6727 12.5914 19.9288 14.3354 18.6887 15.0523C19.9288 15.6336 21.915 17.1548 22.6707 19.9063H19.9191C19.3281 18.0655 17.8556 16.6413 15.9082 16.4475V19.9063H15.6077Z" fill="#E0431E"/>
    </SvgIcon>
  );
}

export default VkFooter;